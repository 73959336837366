@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Roboto-Light';
  src: url('./assets/fonts/roboto/Roboto-Light.ttf');
}

@font-face {
  font-family: 'Roboto-Regular';
  src: url('./assets/fonts/roboto/Roboto-Regular.ttf');
}

@font-face {
  font-family: 'Roboto-Semi';
  src: url('./assets/fonts/roboto/Roboto-Semi.ttf');
}

@font-face {
  font-family: 'Roboto-Bold';
  src: url('./assets/fonts/roboto/Roboto-Bold.ttf');
}

html,
body {
  font-family: 'Roboto-Regular';
  font-size: 14px;
  letter-spacing: 0.025em;
  color: #191919;
}
